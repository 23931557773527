<!-- /* eslint-disable */-->
<template>
  <v-container>
    <!-- Main Title -->
    <v-row no-gutters>
      <v-col cols="12">
        <h1> {{ menuText }}</h1>
      </v-col>
      <v-col cols="12" style="margin:20px 0;">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>가이드</v-expansion-panel-header>
            <v-expansion-panel-content>
              - 플레이스 URL주소는 https://m.place.naver.com/~ 로 시작되는 전체URL주소를 넣어야 정확한 검색이 가능합니다.<br>
              - 플레이스 코드 기반으로 순위을 확인하기 때문에 업체명이 변경 되어도 순위확인이 가능합니다.<br>
              - 일정 기간 순위가 확인되지 않는 슬롯은 순차적으로 자동 삭제 됩니다.<br>
              - 순위 갱신은 오후 12시 30분 이후부터 순차적으로 진행됩니다.<br>
              - 순위 기록은 최근 3개월 내역까지 저장되며, 3개월 경과건은 순차적으로 삭제 될 수 있습니다.<br>
              - 전체 갱신은 최근 체크시간 기준 어제 까지인 플레이스만 동작합니다.<br>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <!-- Collapsible Description -->
    <v-row no-gutters>
    </v-row>
    <!-- Table (takes the remaining space) -->
    <v-row no-gutters>
      <v-col cols="12" class="mt-3 mb-3">
        <v-row justify="end">
          <v-col cols="auto" style="padding: 5px; align-self:center;" class="mr-1">
          </v-col>
          <v-col cols="1" style="padding: 5px;">
            <v-select
                v-model="input_group"
                :items="groupsNoAll"
                item-text="name"
                item-value="groupNo"
                label="그룹"
                hide-details
                return-object
                single-line
                style="margin: 0;"
            ></v-select>
          </v-col>

          <v-col cols="1" style="padding: 5px;">
            <v-text-field
                label="검색어"
                v-model="input_keyword"
                style="margin: 0;"
            ></v-text-field>
          </v-col>

          <v-col cols="auto" style="padding: 5px;">
            <v-text-field
                label="플레이스 주소"
                v-model="input_place_url"
                hint="플레이스 ID가 포함된 URL을 정확히 입력하여야 합니다."
                style="margin: 0;"
            ></v-text-field>
          </v-col>

          <v-col cols="auto" style="align-self: center; padding: 5px;">

            <v-btn color="primary" @click="addEntry()" :disabled="inputProgress" style="margin-right: 12px;">플레이스 추가</v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" style="text-align:right;" class="mt-3 mb-3">
        <v-btn @click="refreshAll" dense style="margin-right:5px;" color="#21c1d6">전체 갱신</v-btn>
        <v-btn style="margin-right:5px;" color="primary" dense @click="openInputCsvDialog">
          플레이스 대량 추가
        </v-btn>
        <v-btn @click="changeGroup" dense style="margin-right:5px;">선택 항목 그룹 변경</v-btn>
        <v-btn @click="deleteContent" dense style="margin-right:5px;" color="red">선택 항목 삭제</v-btn>
      </v-col>

      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="items"
          item-key="no"
          show-select
          v-model:selected="selected"
          hide-default-footer
          mobile-breakpoint="1"
          :page.sync="page"
          :items-per-page="pagePer"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>플레이스 목록</v-toolbar-title>
              <span class="ml-3"> 등록 개수 : {{ totalCount }}</span>
              <v-spacer></v-spacer>

              <v-select
                  v-model="searchGroup"
                  :items="groups"
                  item-text="name"
                  item-value="groupNo"
                  label="그룹"
                  outlined
                  dense
                  hide-details
                  return-object
                  single-line
                  style="max-width: 150px; margin-right: 10px;"
              ></v-select>
              <v-text-field
                  v-model="searchText"
                  label="플레이스/키워드 명"
                  outlined
                  dense
                  hide-details
                  style="max-width: 250px; margin-right: 10px;"
                  @keyup.enter="getContents"
              ></v-text-field>
              <v-btn color="primary" @click="getContents" style="margin-right: 10px;">검색</v-btn>
            </v-toolbar>

            <div class="d-flex flex-column justify-end align-content-end mr-3" style="align-items: end;" >
              <span class="mr-5">페이지 당 조회수</span>
              <v-select
                  v-model="pagePer"
                  :items="pagePerItems"
                  outlined
                  dense
                  hide-details
                  return-object
                  single-line
                  style="max-width: 150px;"
                  class="custom-select"
              ></v-select>
            </div>
          </template>

          <template v-slot:body="{ items }">
            <tbody>
            <template v-for="item in items">
              <tr :key="item.no" class="text-center">
                <td rowspan="2" class="border-bottom-solid">
                  <v-checkbox
                    v-model="selected"
                    :value="item"
                  ></v-checkbox>
                </td>
                <td class="place-responsive-text border-bottom-solid" rowspan="2">{{ item.groupName }}</td>
                <td @click="MoveToSearch(item.keyword)" class="href-effect" >{{ item.keyword }}</td>
                <td class="place-url">{{ item.placeUrl }}</td>
                <td @click="MoveToPlaceUrl(item)" class="href-effect place-responsive-text">{{ item.name }}</td>
                <td class="place-responsive-text">{{ parseInt(item.placeCount).toLocaleString() }} 개</td>
                <td class="place-responsive-text">{{ isNaN(parseInt(item.monthSearchCntPc)) ? item.monthSearchCntPc : parseInt(item.monthSearchCntPc).toLocaleString() }} 회</td>
                <td class="place-responsive-text">{{ isNaN(parseInt(item.monthSearchCntMobile)) ? item.monthSearchCntMobile : parseInt(item.monthSearchCntMobile).toLocaleString() }} 회</td>
                <td class="place-responsive-text">{{ item.createTime }}</td>
                <td class="place-responsive-text">{{ item.rankList[0]?.collect_time.substring(0,16) || '-' }}</td>
                <td rowspan="2" class="border-bottom-solid">
                  <v-btn style="min-width:66px; " color="#21c1d6" small :loading="item.loading" @click="resetContent(item)">
                    갱신
                    <template v-slot:loader>

                      <v-progress-circular indeterminate :size="15"></v-progress-circular>
                    </template>
                  </v-btn><br>
                  <v-btn style="margin-top:5px; color:#FFF; min-width:66px;" small color="grey" @click="showReport(item)">보고서</v-btn><br>
                  <!-- <v-btn style="margin-top:5px; min-width:66px; color:#FFF" small color="#a7a7a7">삭제</v-btn> -->
                </td>
              </tr>
              <tr>
                <td colspan="8" class="border-bottom-solid">
                  <div style="display: inline-block; padding: 5px; text-align:center;"
                   v-for="rankItem in item.rankList" v-bind:key="JSON.stringify(rankItem)">
                    <div style="border-bottom:1px solid #ccc; width:85px; font-weight:500;">{{rankItem.date}}</div>
                    <span class="rankCount">{{rankItem.rank}}위<br> </span>
                    <span class="saveCount" v-if="rankItem.save_count != null">저 {{ rankItem.save_count }}<br></span>
                    <span class="blogCafeReviewCount">블 {{ parseInt(rankItem.blog_cafe_review_count.replaceAll(',', '')).toLocaleString() }}</span><br>
                    <span class="visitorReviewCount">방 {{  parseInt(rankItem.visitor_review_count.replaceAll(',', '')).toLocaleString() }}</span><br>
                  </div>
                </td>
              </tr>
            </template>
            </tbody>
          </template>
        </v-data-table>
        <v-pagination v-model="page" :length="pageCount" @input="getContents"></v-pagination>
      </v-col>

    </v-row>

    <!-- 단건 추가 Dialog -->
    <v-dialog v-model="inputDialog" persistent max-width="500px">
      <div ref="report">
        <v-card>
          <v-card-title>
            <span class="text-h5">플레이스 추가</span>
          </v-card-title>

          <v-card-text>
            <h3>플레이스 주소는 https://m.place.naver.com/~ 의 URL을 정확히 입력해야 합니다.</h3>
            <v-form>
              <!-- Input Field 1 -->
              <v-text-field label="검색어" v-model="input_keyword"></v-text-field>

              <!-- Input Field 2 -->
              <v-text-field label="플레이스 주소" v-model="input_place_url"></v-text-field>
              <v-select
                v-model="input_group"
                :items="groupsNoAll"
                item-text="name"
                item-value="groupNo"
                label="그룹"
                hide-details
                return-object
                single-line
              ></v-select>
            </v-form>
          </v-card-text>

          <v-card-actions style="text-align: right;">
            <!-- Button to close Dialog -->
            <v-spacer></v-spacer>
            <!-- Cancel button -->
            <v-btn color="secondary" text @click="inputDialog = false">취소</v-btn>
            <!-- Add button -->
            <v-btn color="primary" @click="addEntry()" :disabled="inputProgress">추가</v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </v-dialog>
    <!-- CSV 추가 Dialog-->

    <!-- 그룹 변경 Dialog -->
    <v-dialog v-model="changeGroupDialog" persistent max-width="500px">
      <div ref="report">
        <v-card>
          <v-card-title>
            <span class="text-h5">그룹 변경</span>
          </v-card-title>

          <v-card-text>
            <h3>그룹을 선택해주세요.</h3>
            <v-form>
              <v-select
                v-model="changeGroupItem"
                :items="groupsNoAll"
                item-text="name"
                item-value="groupNo"
                label="그룹"
                outlined
                dense
                hide-details
                return-object
                single-line
              ></v-select>
            </v-form>
          </v-card-text>

          <v-card-actions style="text-align: right;">
            <!-- Button to close Dialog -->
            <v-spacer></v-spacer>
            <!-- Cancel button -->
            <v-btn color="secondary" text @click="changeGroupDialog = false">취소</v-btn>
            <!-- Add button -->
            <v-btn color="primary" @click="changeGroupExecute()" :disabled="inputProgress">변경</v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </v-dialog>
    <!-- CSV 추가 Dialog-->

    <v-dialog v-model="csvUploadDialog" persistent max-width="1000px">
      <v-card>
        <v-card-title>
          <span class="text-h5">플레이스 대량 추가</span>
        </v-card-title>

        <v-card-text>
          <h3>엑셀 양식을 활용하여 데이터를 업로드 합니다.</h3>
          <h4>- 엑셀 파일은 양식에 맞추어 업로드해야 합니다. 양식을 다운로드하려면 <a @click="downloadSample">여기</a>를 클릭해주세요.</h4>
          <h4>- 업로드 후 아래 등록 항목을 참고하여 인식이 잘 되었는지 확인합니다.</h4>
          <h4>- 등록량에 따라 수 초에서 수 분의 시간이 소요될 수 있습니다.</h4>
          <br>
          <v-select
              v-model="input_group"
              :items="groupsNoAll"
              item-text="name"
              item-value="groupNo"
              label="그룹"
              hide-details
              return-object
              single-line
          ></v-select>
          <v-file-input
              v-bind="file"
              label="CSV 파일 업로드"
              accept=".csv"
              @change="handleFileUpload"
          ></v-file-input>
        </v-card-text>
        <v-card-text>
          <h5>- 등록 항목 총 {{ uploadData.length }} 건</h5>
          <ul v-if="uploadData.length >= 1">
            <li v-for="(item, i) in uploadData" v-bind:key="i">
              <span v-if="!item.complete" style="color: #700">키워드 : {{ item.keyword }}, URL : {{ item.url }}</span>
              <span v-else style="color:green">키워드 : {{ item.keyword }}, URL : {{ item.url }}</span>
            </li>
          </ul>

          <h5 v-if="csvUploadProgress">등록 진행 중 : ( {{ csvUploadProgressCnt }} / {{ uploadData.length }})</h5>
        </v-card-text>

        <v-card-actions style="text-align: right;">
          <!-- Button to close Dialog -->

          <v-spacer></v-spacer>
          <!-- Cancel button -->
          <v-btn color="secondary" text @click="csvUploadDialog = false">취소</v-btn>
          <!-- Add button -->
          <v-btn color="primary" @click="addCsvEntry()" :disabled="uploadData.length < 1 || csvUploadProgress">{{ uploadData.length }}건 추가</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
        v-model="snackbar"
        color="primary"
        variant="tonal"
        :timeout="2000"
    >
      {{ snackText }}
    </v-snackbar>
    <report-component ref="reportComponent"></report-component>

  </v-container>
</template>

<script>
import { wrapFetch } from '@/config';
import ReportComponent from '/src/components/PlaceReport'

export default {
  name: 'PlaceList',
  components: {ReportComponent},
  props: {
    placeTenant: String,
    menuText: String
  },
  data() {
    return {
      snackbar: false,
      snackText: "",
      selected: [],
      resetLoading: {},
      uploadData: [],
      csvUploadDialog: false,
      csvUploadProgress: false,
      csvUploadProgressCnt: 0,
      inputProgress: false,
      inputDialog: false,
      reportDialog: false,
      file: null,
      headers: [
        { text: '그룹', value: 'groupName', align: 'center'},
        { text: '검색 키워드', value: 'keyword', align: 'center', tooltip: '클릭 시 해당 키워드 검색창으로 이동합니다.'},
        { text: '플레이스 URL 주소', value: 'placeUrl', align: 'center'},
        { text: '플레이스 업체명', value: 'name', align: 'center', tooltip: '클릭 시 해당 플레이스 링크로 이동합니다.'},
        { text: '업체수', value: 'placeCount', align: 'center'},
        { text: '월 조회수(PC)', value: 'monthSearchCntPc', align: 'center'},
        { text: '월 조회수(모바일)', value: 'monthSearchCntMobile', align: 'center'},
        { text: '등록일', value: 'create_time', align: 'center'},
        { text: '최근 체크시간', value: 'collectTime', align: 'center'},
        { text: '', value: 'utillity' },
      ],
      searchText: "",
      searchGroup: "",
      changeGroupDialog: false,
      changeGroupItem: "",
      groups: [],
      groupsNoAll: [],
      pagePerItems: [5, 10, 20, 100],
      items: [],
      input_group: '',
      input_keyword: '',
      input_place_url: '',
      totalCount: 0,
      page: 1,
      pagePer: 5,
    };
  },
  computed: {
    pageCount() {
      return Math.ceil(this.totalCount / this.pagePer)
    },
  },
  methods: {
    addEntry(){
      let self = this;
      self.inputProgress = true;

      wrapFetch('/place', "POST", {
        keyword: this.input_keyword,
        placeUrl: this.input_place_url,
        groupNo: parseInt(this.input_group.groupNo),
        placeTenant: this.placeTenant,
      })
          .then(() => {
            self.snackText = "플레이스 추가가 완료되었습니다.";
            self.snackbar = true;
            self.inputDialog = false;
            self.inputProgress = false;
            self.input_group = '';
            self.input_keyword = '';
            self.input_place_url = '';
            self.getContents();
          }).catch(() => {
        self.inputProgress = false;
      })
    },
    getContents(){
      let self = this;
      self.items = [];
      self.selected = [];
      self.groups = [
        {name: '전체', groupNo: ""},
        {name: '기본 그룹', groupNo: 1}
      ];
      self.groupsNoAll = [
        {name: '기본 그룹', groupNo: 1}
      ];
      let searchGroup = self.searchGroup.groupNo != undefined ? self.searchGroup.groupNo : "";

      wrapFetch(`/place?searchText=${self.searchText}&searchGroup=${searchGroup}&placeTenant=${self.placeTenant}&page=${self.page}&pagePer=${self.pagePer}`, "GET")
          .then((data) => {
            this.totalCount = data.placeTotalCount
            data.placeList.map((e) => {
              e.loading = false;
              self.items.push(e);
            })
            data.placeGroupList.map((e) => {
              self.groups.push({
                name: e.name,
                groupNo: e.groupNo
              })
              self.groupsNoAll.push({
                name: e.name,
                groupNo: e.groupNo
              })
            })
          })
    },
    changeGroup(){
      if(this.selected.length < 1){
        alert("그룹을 변경할 플레이스를 선택해주세요.");
      }else{
        this.changeGroupDialog = true;
      }
    },
    changeGroupExecute(){
      let self = this;
      let changeItem = this.selected.map( e => {
        return {no: e.no, groupNo: self.changeGroupItem.groupNo}
      });
      wrapFetch("/place/group", "PUT", changeItem)
          .then(() => {
            self.snackText = "그룹 변경이 완료되었습니다.";
            self.snackbar = true;
            self.changeGroupDialog = false;
            self.getContents();
          })

    },
    resetContent(item){
      let self = this;
      item.loading = true;
      wrapFetch('/place/reload', "POST", item)
          .then((data) => {
            item.rankList = [];
            data.reloadPlace.rankList.forEach( e => {
              item.rankList.push(e);
            })
            item.monthSearchCntPc = data.reloadPlace.monthSearchCntPc;
            item.monthSearchCntMobile = data.reloadPlace.monthSearchCntMobile;
            item.placeCount = data.reloadPlace.placeCount;
            item.loading = false;

            self.snackText = item.name + " 플레이스 갱신이 완료되었습니다.";
            self.snackbar = true;
          })
          .catch(() => {
            item.loading = false;
          })
    },
    deleteContent(){
      let self = this;
      if(self.selected.length === 0){
        alert("삭제할 데이터를 선택해주세요.");
        return;
      }

      if(confirm(`정말 선택된 ${self.selected.length}개의 데이터를 삭제하시겠습니까?`)){
        wrapFetch('/place/delete', "POST", self.selected)
            .then(() => {
              self.snackText = "플레이스 삭제가 완료되었습니다.";
              self.snackbar = true;
              self.selected = [];
              self.selected.pop();
              self.getContents();
            })
      }
    },
    handleFileUpload(file) {
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const content = e.target.result;
          const csvData = this.parseCSV(content);
          this.uploadData = [];

          for(let i=1;i < csvData.length; i++){
            if(csvData[i][0] != ''){
              csvData[i][1] = csvData[i][1].replace("\r", "");
              this.uploadData.push({
                keyword: csvData[i][0],
                url: csvData[i][1],
                complete: false
              });
            }
          }
        };
        reader.readAsText(file);
      }
    },
    parseCSV(content) {
      const rows = content.split("\n");
      return rows.map(row => row.split(","));
    },
    downloadSample(){
      const csvContent = `\uFEFF검색어,플레이스 URL 주소
상암 미용실,https://m.place.naver.com/hairshop/1353822419,*해당 데이터는 샘플입니다. 해당 줄을 삭제한 뒤 작성해주세요!`;

      // Blob 객체를 생성하여 CSV 데이터를 담음
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

      // 다운로드 링크 생성
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "sample.csv");  // 파일 이름 지정
      link.style.visibility = "hidden";

      // 문서에 링크를 추가하고 클릭 이벤트로 다운로드 실행
      document.body.appendChild(link);
      link.click();

      // 다운로드 후 링크 제거
      document.body.removeChild(link);
    },
    openInputCsvDialog(){
      this.file = null;
      this.uploadData = [];
      this.input_group = {name: '기본 그룹', groupNo: 1};
      this.csvUploadDialog = true;
    },
    openInputDialog(){
      this.input_keyword = "";
      this.input_place_url = "";
      this.input_group = {name: '기본 그룹', groupNo: 1};
      this.inputDialog = true;
    },
    async addCsvEntry(){
      let self = this;
      this.csvUploadProgress = true;
      for(let i=0; i<this.uploadData.length; i++){
        let e = this.uploadData[i];
        let result = await wrapFetch('/place', "POST", {
          keyword: e.keyword,
          placeUrl: e.url,
          placeTenant: self.placeTenant,
          groupNo: parseInt(self.input_group.groupNo)
        })
        if(result.resultCode == "OK"){
          e.complete = true;
        }
        self.csvUploadProgressCnt++;
      }
      setTimeout( () => {
        self.snackText = "플레이스 추가가 완료되었습니다.";
        self.snackbar = true;
        self.inputDialog = false;
        self.csvUploadDialog = false;
        self.getContents();
      }, 1)
    },
    showReport(item){
      this.reportDialog = true;
      this.$refs.reportComponent.loadReport(item.no);
    },
    refreshAll(){
      let self = this;
      
      if( self.items.some(e => e.loading) ){
        alert("이미 갱신이 진행중입니다.");
        return;
      }

      const now = new Date();
      now.setHours(0, 0, 0, 0);

      let reloadItem = self.items.filter(e => {
        let lastUpdateTime = new Date(e.rankList[0]?.collect_time);
        return lastUpdateTime.getTime() < now.getTime()
      })
      if(confirm(`최근 체크시간 기준 어제 이전인 플레이스 ${reloadItem.length}개만 갱신이 진행됩니다. 진행하시겠습니까?`)){
        reloadItem.forEach(e => {
          self.resetContent(e);
        })
      }
    },
    MoveToSearch(keyword) {
      window.open('https://search.naver.com/search.naver?query=' + encodeURIComponent(keyword), '_blank')
    },
    MoveToPlaceUrl(item) {
      window.open("https://m.place.naver.com/place/" + item.placeId, '_blank')
    }
  },
  mounted() {
    this.getContents();
  }
};
</script>

<style scoped>
.flex-grow-1 {
  flex-grow: 1;
}
v-container.fill-height {
  height: 100vh;
}
.v-data-table__wrapper > table > tbody > tr:hover {
  background: inherit !important;
}
.rankCount{
  font-weight: bold;
}
.blogCafeReviewCount {
  font-size: 0.9em;
  color: #3f51b5;
  font-weight: bold;
}
.visitorReviewCount {
  font-size: 0.9em;
  color: #F9A825;
  font-weight: bold;
}
.saveCount {
  font-size: 0.9em;
  color: #BF360C;
  font-weight: bold;
}
.href-effect {
  color: #0056b3;
  font-weight:bold;
}
.href-effect:hover {
  cursor: pointer
}
.border-bottom-solid {
  border-bottom: 2px solid rgba(0, 0, 0, 0.3) !important;
}
</style>
<style>
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  border-bottom: 2px solid rgba(0, 0, 0, 0.3) !important;
}
</style>
